$(function () {
  'use strict';

  var $bmtStep1Page = $('#sign-up-step1');
  var $bmtLoginPage = $('#sign-up-login');
  var $bmtResetPasswordPage = $('#sign-up-reset-password');
  var $resetTokenPasswordPage = $('#reset-page');
  var $createOrgUserForm = $('#create-org-user-form');

  if (
    !$bmtStep1Page.length &&
    !$bmtLoginPage.length &&
    !$bmtResetPasswordPage.length &&
    !$resetTokenPasswordPage.length &&
    !$createOrgUserForm.length
  ) {
    // don't load for non-bmt flow
    return;
  }

  var $passwordField = $('#password')[0];
  var $showPasswordLabel = $('.emailSignUpForm-showPassword')[0];

  $('#show-password').change(function () {
    $passwordField.type = $(this).prop('checked') ? 'text' : 'password';

    if (typeof $showPasswordLabel !== 'undefined') {
      $showPasswordLabel.innerHTML = $(this).prop('checked')
        ? 'Hide Password'
        : 'Show Password';
    }
  });

  $('#email-form, #login-form, #create-org-user-form').validate({
    submitHandler: function (form) {
      $(form).find('button[type=submit]').prop('disabled', true);
      form.submit();
    },
    errorPlacement: function (error, element) {
      // in the case that the 'ent-name-message' is present we dont want the
      // email validation error to show when/if the user clicks on the
      // ent contact link in the message. The problem is that when the input loses
      // focus, the ent-name-message and the contact-us link are moved down to make
      // room for the jquery validation error. This happens before the link receives the
      // click and so nothing happens ...
      // oh the woes of not having redux
      const classList = [...element[0].classList];
      if (classList.includes('dont-show-error')) {
        return false;
      }

      if (element.prop('type') === 'checkbox') {
        error.insertAfter(element.next());
      } else {
        error.insertAfter(element);
      }
    },
  });

  $('#password').validate({
    errorPlacement: function (error, element) {
      const classList = [...element[0].classList];
      if (classList.includes('dont-show-error')) {
        return false;
      }
    },
  });

  var $sendCodeForm = $('#send-code-form');
  if ($sendCodeForm.length) {
    $sendCodeForm.validate();
  }
});
