$(function () {
  var slideDuration = 600;
  var $commentInput = $('#comment');

  $(
    'input[name="answer_id"]:radio, input[name="subresponse-answer"]:radio'
  ).change(function () {
    var $elemsToHide = $('.expandable-response').not($(this).parents());

    $elemsToHide
      .stop(true, true)
      .fadeIn({ duration: slideDuration, queue: false })
      .css('display', 'none')
      .slideUp(slideDuration);

    $elemsToHide.find('input:radio').attr('checked', false);

    if (this.checked) {
      var expandableResponseElem = $(this).siblings('.expandable-response');

      $(expandableResponseElem)
        .stop(true, true)
        .fadeIn({ duration: slideDuration, queue: false })
        .css('display', 'none')
        .slideDown(slideDuration);
    }
  });

  $('.comment-clearing-input').change(function () {
    if (this.checked) {
      $commentInput.val('');

      $('.comment-input-textarea').val('');
    }
  });

  $('.comment-input-textarea').bind('input propertychange', function () {
    $commentInput.val(this.value);
  });

  $('.comment-input-radio').change(function () {
    if (this.checked) {
      $commentInput.val($(this).attr('data-value'));
    }
  });
});

var SurveyRedirect = function (args) {
  args = args || {};
  this.duration = args.duration || 10;
  this.duration++;
  this.updateDuration();
};

SurveyRedirect.prototype.updateDuration = function () {
  var self = this;
  this.duration--;

  if (this.duration <= 0) {
    window.location.href = '/';
  } else {
    $('#redirect_time_remaining').html(
      this.duration + ' second' + (this.duration === 1 ? '' : 's')
    );

    setTimeout(function () {
      self.updateDuration();
    }, 1000);
  }
};

window.SurveyRedirect = SurveyRedirect; // Exposes for SiteJavascriptWidgetView
window.dispatchEvent(new Event('SurveyRedirect')); // Signals that definition is ready for use
